import React, { useEffect, useState, useContext } from "react";
import { collection, getDocs } from "firebase/firestore";
import { useFirestore } from "../../firebase/FirestoreContext";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { EnvContext } from "../../../context/EnvContext";
import config from "../../../config";

const ProductRequestCMS = () => {
  const db = useFirestore();
  const environment = useContext(EnvContext);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  useEffect(() => {
    const fetchRequests = async () => {
      setLoading(true);
      try {
        const snapshot = await getDocs(collection(db, `${collectionPath}ProductRequest/ProductRequest/DataBase`));
        const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setRequests(data);
      } catch (error) {
        console.error("Error fetching product requests: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, [db, collectionPath]);

  return (
    <div>
      <div style={{ padding: "20px" }}>
        <h3>Product Requests</h3>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>User ID</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Brands</TableCell>
                  <TableCell>Products</TableCell>
                  <TableCell>Additional Info</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {requests.map((request) => (
                  <TableRow key={request.id}>
                    <TableCell>{request.userId || "Anonymous"}</TableCell>
                    <TableCell>{request.email || "Not provided"}</TableCell>
                    <TableCell>
                      {request.brands?.length > 0 ? (
                        <ul style={{ margin: 0, padding: 0, verticalAlign: "top" }}>
                          {request.brands.map((brand, index) => (
                            <li key={index}>{brand}</li>
                          ))}
                        </ul>
                      ) : (
                        "No brands suggested"
                      )}
                    </TableCell>
                    <TableCell>
                      {request.products?.length > 0 ? (
                        <ul style={{ margin: 0, padding: 0, verticalAlign: "top" }}>
                          {request.products.map((product, index) => (
                            <li key={index}>{product}</li>
                          ))}
                        </ul>
                      ) : (
                        "No products suggested"
                      )}
                    </TableCell>
                    <TableCell>{request.additionalInfo || "No additional info"}</TableCell>
                    <TableCell>{new Date(request.createdAt).toLocaleString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default ProductRequestCMS;
