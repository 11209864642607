import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFirestore } from "../../firebase/FirestoreContext";
import { getDoc, doc, collection, getDocs, updateDoc, deleteDoc, writeBatch, addDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../config";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { TableSortLabel } from "@mui/material";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { EnvContext } from "../../../context/EnvContext";
import { CSVLink } from "react-csv";

const EditProduct = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore();
  const { id } = useParams();
  const navigate = useNavigate();

  // Determine the base path based on the environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [sortConfig, setSortConfig] = useState({
    key: "product_name",
    direction: "asc",
  });

  const [brands, setBrands] = useState([]);
  const [dosingGuides, setDosingGuides] = useState([]);
  const [products, setProducts] = useState([]);
  const [productData, setProductData] = useState({
    product_uid: "",
    brand_uid: "",
    product_name: "",
    product_description: "",
    potency_value: "0",
    weight_units: "",
    net_weight: "0",
    product_category: "",
    effects: "",
    strength: "",
    product_type: "",
    number_servings: 0,
    serving_size: "",
    dose_per_serving: 0,
    dosing_guide: "",
    images: [],
    perishable: false,
    inactive: false,
    rating_initial: {
      rating: 0,
      voting: 0,
    },
  });
  const [loading, setLoading] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [currentTab, setCurrentTab] = useState("form");
  const [files, setFiles] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  //
  const [locations, setLocations] = useState([]);
  const [selectedCompliance, setSelectedCompliance] = useState(productData?.compliance || []);

  const fileInputRef = useRef(null);

  //
  const effectsOptions = config.effectsOptions;
  const productTypeOptions = config.productTypeOptions;
  const productCategoryOptions = config.productCategoryOptions;
  const weightUnitsOptions = config.weightUnitsOptions;
  const strain_typeOptions = config.strain_typeOptions;

  // compliance
  useEffect(() => {
    if (currentTab === "compliance") {
      const fetchLocations = async () => {
        const locationsCollectionRef = collection(db, `${collectionPath}SooperstockSettings/ShipmentCompliance/DataBase`);
        const snapshot = await getDocs(locationsCollectionRef);
        const locationsArray = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLocations(locationsArray);
      };

      fetchLocations();
    }
  }, [currentTab, db, collectionPath]);

  const handleConstraintChange = (locationShortName) => {
    setSelectedCompliance((prevSelected) =>
      prevSelected.includes(locationShortName) ? prevSelected.filter((constraint) => constraint !== locationShortName) : [...prevSelected, locationShortName]
    );
  };
  const saveCompliance = async () => {
    try {
      const productDocRef = doc(db, `${collectionPath}Products/ProductsList/DataBase`, id);

      // Update both compliance and compliance_type
      await updateDoc(productDocRef, {
        compliance: selectedCompliance,
        compliance_type: "custom", // Set compliance_type to "custom"
      });

      toast.success("Compliance saved successfully.");
    } catch (error) {
      console.error("Error saving compliance:", error);
      toast.error("Failed to save compliance.");
    }
  };

  useEffect(() => {
    if (id) {
      const productDocRef = doc(db, `${collectionPath}Products/ProductsList/DataBase`, id);

      getDoc(productDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();
            setProductData(data);

            // Initialize selectedCompliance from the product's compliance field
            setSelectedCompliance(data.compliance || []);
          } else {
            console.error("No such product document!");
          }
        })
        .catch((error) => {
          console.error("Error fetching product:", error);
        });
    } else {
      const productsCollectionRef = collection(db, `${collectionPath}Products/ProductsList/DataBase`);

      getDocs(productsCollectionRef)
        .then((querySnapshot) => {
          const productsArray = [];
          querySnapshot.forEach((doc) => {
            productsArray.push({ id: doc.id, ...doc.data() });
          });
          setProducts(productsArray);
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
        });
    }

    const fetchBrands = async () => {
      const brandsCollectionRef = collection(db, `${collectionPath}Brands/BrandsList/DataBase`);
      const querySnapshot = await getDocs(brandsCollectionRef);
      const brandsArray = [];
      querySnapshot.forEach((doc) => {
        brandsArray.push({ id: doc.id, ...doc.data() });
      });
      setBrands(brandsArray);
    };

    const fetchDosingGuides = async () => {
      const dosingGuidesCollectionRef = collection(db, `${collectionPath}DosingGuides/DosingGuidesList/DataBase`);
      const querySnapshot = await getDocs(dosingGuidesCollectionRef);
      const guidesArray = [];
      querySnapshot.forEach((doc) => {
        guidesArray.push(doc.data().name);
      });
      setDosingGuides(guidesArray);
    };

    fetchBrands();
    fetchDosingGuides();
  }, [db, id, collectionPath]);

  //
  useEffect(() => {
    // Fetch products on component mount
    const fetchProducts = async () => {
      try {
        const productsCollectionRef = collection(db, `${collectionPath}Products/ProductsList/DataBase`);
        const productsSnapshot = await getDocs(productsCollectionRef);

        // Step 1: Build a local product map
        const productsMap = {};
        const productsArray = productsSnapshot.docs.map((doc) => {
          const productData = { id: doc.id, ...doc.data(), sold_count: 0 }; // Initialize sold_count to 0
          productsMap[doc.id] = productData;
          return productData;
        });

        // Step 2: Fetch all orders and calculate sold counts
        const ordersCollectionRef = collection(db, `${collectionPath}Orders/OrdersList/DataBase`);
        const ordersSnapshot = await getDocs(ordersCollectionRef);

        ordersSnapshot.docs.forEach((orderDoc) => {
          const orderData = orderDoc.data();
          if (Array.isArray(orderData.items)) {
            orderData.items.forEach((item) => {
              if (item.product_id && productsMap[item.product_id]) {
                productsMap[item.product_id].sold_count += item.quantity || 0; // Increment sold_count
              }
            });
          }
        });

        // Step 3: Update the products state with sold counts
        setProducts(Object.values(productsMap)); // Convert the map back to an array
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, [db, collectionPath]);

  // Function to generate CSV data
  const generateCsvData = () => {
    const headers = [
      { label: "Product ID", key: "product_uid" },
      { label: "Name", key: "product_name" },
      { label: "Brand", key: "brand_name" },
      { label: "Category", key: "product_category" },
      { label: "Description", key: "product_description" },
      { label: "Potency Value", key: "potency_value" },
      { label: "Net Weight", key: "net_weight" },
      { label: "Weight Units", key: "weight_units" },
      { label: "Effects", key: "effects" },
      { label: "Strength", key: "strength" },
      { label: "Inactive", key: "inactive" },
    ];

    const csvData = products.map((product) => ({
      product_uid: product.product_uid || "",
      product_name: product.product_name || "",
      brand_name: product.brand_name || "",
      product_category: product.product_category || "",
      product_description: product.product_description || "",
      potency_value: product.potency_value || "",
      net_weight: product.net_weight || "",
      weight_units: product.weight_units || "",
      effects: product.effects || "",
      strength: product.strength || "",
      inactive: product.inactive ? "Yes" : "No",
    }));

    return { headers, data: csvData };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!productData.product_uid || !productData.brand_uid || !productData.product_name) {
      alert("Please fill out all required fields");
      setLoading(false);
      return;
    }

    const productDocRef = doc(db, `${collectionPath}Products/ProductsList/DataBase`, id);

    const selectedBrand = brands.find((brand) => brand.brand_uid === Number(productData.brand_uid));

    const updatedProduct = {
      product_uid: productData.product_uid,
      brand_uid: productData.brand_uid,
      brand_name: selectedBrand ? selectedBrand.brand_name : "",
      product_name: productData.product_name,
      product_description: productData.product_description || "",
      potency_value: productData.potency_value || "0",
      net_weight: productData.net_weight || "0",
      weight_units: productData.weight_units || "",
      product_category: productData.product_category || "",
      strain_type: productData.strain_type || "",
      effects: productData.effects || "",
      strength: productData.strength || "",
      product_type: productData.product_type || "",
      number_servings: productData.number_servings || 0,
      serving_size: productData.serving_size || "",
      dosing_guide: productData.dosing_guide || "",
      dose_per_serving: productData.dose_per_serving || 0,
      images: productData.images || [],
      perishable: productData.perishable,
      inactive: productData.inactive || false,
      rating_initial: {
        rating: productData?.rating_initial?.rating || 0,
        voting: productData?.rating_initial?.voting || 0,
      },
    };

    updateDoc(productDocRef, updatedProduct)
      .then(() => {
        toast.success("Changes saved successfully");
        navigate(`/main/products/edit/${id}`);
      })
      .catch((error) => {
        toast.error("Error saving changes");
        console.error("Error updating product:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formatUID = (uid, totalLength = 8) => {
    return String(uid).padStart(totalLength, "0");
  };

  const handleTabClick = (tab) => {
    setCurrentTab(tab);
  };

  const handleFileChange = (event) => {
    setFiles([...event.target.files]);
  };

  //
  const handleDeleteProduct = async () => {
    setLoading(true);
    try {
      // Step 1: Delete product from the Products collection
      const productDocRef = doc(db, `${collectionPath}Products/ProductsList/DataBase`, id);

      // Step 2: Find all listings that match the product_id
      const listingsCollectionRef = collection(db, `${collectionPath}Listings/ListingsList/DataBase`);
      const querySnapshot = await getDocs(listingsCollectionRef);

      // ** Batch Initialization using writeBatch(db) **
      const batch = writeBatch(db); // Use writeBatch in Firestore v9+

      querySnapshot.forEach((docSnapshot) => {
        const listing = docSnapshot.data();
        if (listing.product_id === id) {
          // Assuming `id` is the product_id in listings
          const listingDocRef = doc(db, `${collectionPath}Listings/ListingsList/DataBase`, docSnapshot.id);
          batch.delete(listingDocRef); // Add each deletion to the batch
        }
      });

      // Commit batch deletion of all related listings
      await batch.commit();

      // Step 3: Delete the product itself
      await deleteDoc(productDocRef);

      toast.success("Product and associated listings deleted successfully");
      navigate(`/main/products/edit`);
    } catch (error) {
      console.error("Error details:", error); // Log the full error for debugging
      toast.error("Error deleting product and listings");
    } finally {
      setShowDeleteDialog(false);
      setLoading(false);
    }
  };

  const renderDeleteDialog = () => (
    <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete this product? This action cannot be undone.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowDeleteDialog(false)} color="primary">
          No
        </Button>
        <Button onClick={handleDeleteProduct} color="error">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );

  //
  //
  // Compliance
  const renderComplianceTab = () => {
    // Function to handle select/unselect all
    const handleSelectAll = (isChecked) => {
      if (isChecked) {
        setSelectedCompliance(locations.map((location) => location.shortName));
      } else {
        setSelectedCompliance([]);
      }
    };

    return (
      <div>
        <h2>Manage Product Compliance</h2>
        <div style={{ marginBottom: "10px" }}>
          <label>
            <input
              type="checkbox"
              checked={selectedCompliance.length === locations.length && locations.length > 0}
              onChange={(e) => handleSelectAll(e.target.checked)}
            />
            Select/Unselect All
          </label>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "10px" }}>
          {locations
            .slice() // Create a copy of the locations array
            .sort((a, b) => a.name.localeCompare(b.name)) // Sort by name
            .map((location) => (
              <div
                key={location.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #ccc",
                  padding: "5px 10px",
                  borderRadius: "4px",
                }}
              >
                <span>
                  {location.name} ({location.shortName})
                </span>
                <input type="checkbox" checked={selectedCompliance.includes(location.shortName)} onChange={() => handleConstraintChange(location.shortName)} />
              </div>
            ))}
        </div>
        <button
          onClick={saveCompliance}
          style={{
            ...styles.button,
            marginTop: 20,
            backgroundColor: "#478169",
          }}
        >
          Save Compliance
        </button>
      </div>
    );
  };

  //
  //
  // Image

  const handleImageUpload = async (event) => {
    event.preventDefault();
    setLoading(true);

    let updatedImages = [...(productData.images || [])];

    for (const file of files) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const apiUrl = `${config.apiConfig.API_ENDPOINT_URL}${environment === "staging" ? "/api/dev" : "/api"}/product/productImages/${id}`;

        const response = await fetch(apiUrl, {
          method: "POST",
          body: formData,
        });
        const result = await response.json();

        if (response.ok && Array.isArray(result.imageUrls)) {
          for (const imageUrl of result.imageUrls) {
            // Introduce a slight delay before adding the image
            await new Promise((resolve) => setTimeout(resolve, 1000)); // 1-second delay

            updatedImages.push(imageUrl);
            setProductData((prevState) => ({
              ...prevState,
              images: updatedImages,
            }));
          }
        } else {
          throw new Error(`Failed to upload: ${file.name}, server responded with: ${response.status} and message: ${JSON.stringify(result)}`);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        alert("Error uploading image - " + error.message);
      }
    }

    fileInputRef.current.value = ""; // Clear the file input
    setLoading(false);
  };

  const deleteImage = async (imageIndex) => {
    setLoading(true);

    if (!imageIndex && imageIndex !== 0 && imageIndex !== "0") {
      console.error("Invalid image index:", imageIndex);
      toast.error("Invalid image index");
      setLoading(false);
      return;
    }
    // Delay deletion to avoid conflicts with drag-and-drop
    setTimeout(async () => {
      const updatedImages = productData.images.filter((_, index) => index !== imageIndex);

      try {
        const productDocRef = doc(db, `${collectionPath}Products/ProductsList/DataBase`, id);
        await updateDoc(productDocRef, {
          images: updatedImages,
        });
        setProductData({ ...productData, images: updatedImages });
        toast.success("Image deleted successfully");
      } catch (error) {
        console.error("Error deleting image:", error);
        toast.error("Error deleting image");
      } finally {
        setLoading(false);
      }
    }, 100); // 100ms delay
  };

  //
  // Product copy

  //
  const getNewProductUID = async () => {
    const productsCollectionRef = collection(db, `${collectionPath}Products/ProductsList/DataBase`);
    const productsSnapshot = await getDocs(productsCollectionRef);
    let maxUID = 0;
    productsSnapshot.forEach((doc) => {
      const data = doc.data();
      const uid = parseInt(data.product_uid, 10);
      if (uid > maxUID) {
        maxUID = uid;
      }
    });
    return maxUID + 1;
  };

  const handleDuplicateProduct = async () => {
    if (!productData.product_name) {
      alert("No product data available to duplicate.");
      return;
    }

    setLoading(true);

    try {
      // Create a new product data object with the same properties as the current one
      const duplicatedProduct = {
        ...productData,
        product_name: `COPY of ${productData.product_name}`, // Change the name to "COPY of Product X"
        product_uid: await getNewProductUID(), // Make sure the new product has a unique UID
      };

      // Get a reference to the collection where the product will be added
      const productsCollectionRef = collection(db, `${collectionPath}Products/ProductsList/DataBase`);

      // Add the duplicated product to the Firestore collection
      const newProductRef = await addDoc(productsCollectionRef, duplicatedProduct);

      toast.success(`Product duplicated successfully! New product ID: ${newProductRef.id}`);

      // Redirect to the edit page of the newly duplicated product
      navigate(`/main/products/edit/${newProductRef.id}`);
    } catch (error) {
      toast.error("Error duplicating product.");
      console.error("Error duplicating product:", error);
    } finally {
      setLoading(false);
    }
  };

  // Sorting products
  const handleSortRequest = (property) => {
    const isAsc = sortConfig.key === property && sortConfig.direction === "asc";
    setSortConfig({ key: property, direction: isAsc ? "desc" : "asc" });
  };

  const sortedProducts = [...products].sort((a, b) => {
    const order = sortConfig.direction === "asc" ? 1 : -1;
    const aValue = sortConfig.key === "brand" ? a.brand_name : a[sortConfig.key];
    const bValue = sortConfig.key === "brand" ? b.brand_name : b[sortConfig.key];

    if (aValue < bValue) {
      return -1 * order;
    }
    if (aValue > bValue) {
      return 1 * order;
    }
    return 0;
  });

  // Images sorting

  const SortableImage = SortableElement(({ image, customIndex, index, onDelete }) => (
    <div key={customIndex} style={{ flexDirection: "column", marginBottom: 15 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 10,
          padding: 2,
          border: "1px solid silver",
          marginRight: 10,
        }}
      >
        <img
          src={image}
          alt="product"
          style={{
            width: 150,
            height: 150,
            objectFit: "cover",
          }}
        />
      </div>
      <div
        onMouseDown={(e) => {
          e.stopPropagation(); // Stop drag system from recognizing this
          e.preventDefault(); // Prevent drag behavior
          onDelete(customIndex); // Trigger delete
        }}
        style={{
          fontSize: 12,
          color: "red",
          cursor: "pointer",
        }}
      >
        Delete
      </div>
    </div>
  ));

  const SortableImageList = SortableContainer(({ images, onDelete }) => (
    <div
      style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
      onMouseDown={() => setDragging(false)} // Reset dragging state on mouse down
      onMouseUp={() => setDragging(false)} // Reset dragging state on mouse up
    >
      {images && images.map((image, index) => <SortableImage key={`image-${index}`} customIndex={index} image={image} onDelete={onDelete} />)}
    </div>
  ));

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const newImages = arrayMoveImmutable(productData.images, oldIndex, newIndex);
    setProductData({ ...productData, images: newImages });

    // Update Firestore with the new image order
    const productDocRef = doc(db, `${collectionPath}Products/ProductsList/DataBase`, id);
    try {
      await updateDoc(productDocRef, { images: newImages });
      toast.success("Image order updated successfully");
    } catch (error) {
      console.error("Error updating image order:", error);
      toast.error("Failed to update image order");
    }
  };

  // Images sorting END

  if (id) {
    return (
      <div>
        {renderDeleteDialog()}

        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <h2>
          Edit Product
          {productData.product_name && (
            <>
              <span>: {productData.product_name}</span> <span></span>
            </>
          )}
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: 20,
          }}
        >
          <div
            onClick={() => handleTabClick("form")}
            style={{
              ...styles.tabButton,
              backgroundColor: currentTab === "form" ? "#ff0000" : "#d5d4d4",
              color: currentTab === "form" ? "white" : "gray",
            }}
          >
            Edit Product Data
          </div>
          <div
            onClick={() => handleTabClick("compliance")}
            style={{
              ...styles.tabButton,
              backgroundColor: currentTab === "compliance" ? "#ff0000" : "#d5d4d4",
              color: currentTab === "compliance" ? "white" : "gray",
            }}
          >
            Compliance
          </div>

          <div
            onClick={() => handleTabClick("images")}
            style={{
              ...styles.tabButton,
              backgroundColor: currentTab === "images" ? "#ff0000" : "#d5d4d4",
              color: currentTab === "images" ? "white" : "gray",
            }}
          >
            Edit Product Images
          </div>
        </div>
        {currentTab === "form" && (
          <form onSubmit={handleSubmit} style={styles.form}>
            <div style={{ padding: "20px" }}>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Product UID:</label>
                <input
                  type="text"
                  value={`P${formatUID(productData.product_uid)}`}
                  onChange={(e) =>
                    setProductData({
                      ...productData,
                      product_uid: e.target.value,
                    })
                  }
                  style={styles.formInput}
                  disabled
                />
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Brand&#42;:</label>
                <select
                  value={productData.brand_uid}
                  onChange={(e) =>
                    setProductData({
                      ...productData,
                      brand_uid: e.target.value,
                    })
                  }
                  style={styles.formSelect}
                >
                  <option key={""} value={""}></option>
                  {brands.map((brand) => (
                    <option key={brand.brand_uid} value={brand.brand_uid}>
                      {brand.brand_name}
                    </option>
                  ))}
                </select>
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Name&#42;:</label>
                <input
                  type="text"
                  value={productData.product_name}
                  onChange={(e) =>
                    setProductData({
                      ...productData,
                      product_name: e.target.value,
                    })
                  }
                  style={styles.formInput}
                />
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Description:</label>
                <textarea
                  value={productData.product_description}
                  onChange={(e) =>
                    setProductData({
                      ...productData,
                      product_description: e.target.value,
                    })
                  }
                  style={styles.formInput}
                ></textarea>
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Strain Type:</label>
                <select
                  value={productData.strain_type}
                  onChange={(e) =>
                    setProductData({
                      ...productData,
                      strain_type: e.target.value,
                    })
                  }
                  style={styles.formSelect}
                >
                  <option value=""></option>
                  {strain_typeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Effects:</label>
                <select value={productData.effects} onChange={(e) => setProductData({ ...productData, effects: e.target.value })} style={styles.formSelect}>
                  <option value=""></option>
                  {effectsOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Strength:</label>
                <input
                  type="text"
                  value={productData.strength}
                  onChange={(e) => setProductData({ ...productData, strength: e.target.value })}
                  style={styles.formInput}
                />
              </div>

              <div style={styles.formElement}>
                <label style={styles.formLabel}>Product Type:</label>
                <select
                  value={productData.product_type}
                  onChange={(e) =>
                    setProductData({
                      ...productData,
                      product_type: e.target.value,
                    })
                  }
                  style={styles.formSelect}
                >
                  <option value=""></option>
                  {productTypeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Product Category:</label>
                <select
                  value={productData.product_category}
                  onChange={(e) =>
                    setProductData({
                      ...productData,
                      product_category: e.target.value,
                    })
                  }
                  style={styles.formSelect}
                >
                  <option value=""></option>
                  {productCategoryOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Dosing Guide:</label>
                <select
                  value={productData.dosing_guide}
                  onChange={(e) =>
                    setProductData({
                      ...productData,
                      dosing_guide: e.target.value,
                    })
                  }
                  style={styles.formSelect}
                >
                  <option value=""></option>
                  {dosingGuides.map((guide) => (
                    <option key={guide} value={guide}>
                      {guide}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ ...styles.formElement, marginRight: 20 }}>
                  <label style={styles.formLabel}>Weight Value:</label>
                  <input
                    type="text"
                    value={productData.net_weight}
                    onChange={(e) =>
                      setProductData({
                        ...productData,
                        net_weight: e.target.value,
                      })
                    }
                    style={styles.formInput}
                  />
                </div>
                <div style={styles.formElement}>
                  <label style={styles.formLabel}>Weight Unit:</label>
                  <select
                    value={productData.weight_units}
                    onChange={(e) =>
                      setProductData({
                        ...productData,
                        weight_units: e.target.value,
                      })
                    }
                    style={styles.formSelect}
                  >
                    <option value=""></option>
                    {weightUnitsOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Number of Servings:</label>
                <input
                  type="text"
                  value={productData.number_servings}
                  onChange={(e) =>
                    setProductData({
                      ...productData,
                      number_servings: e.target.value,
                    })
                  }
                  style={styles.formInput}
                />
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Serving Size:</label>
                <input
                  type="text"
                  value={productData.serving_size}
                  onChange={(e) =>
                    setProductData({
                      ...productData,
                      serving_size: e.target.value,
                    })
                  }
                  style={styles.formInput}
                />
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Dose per Serving:</label>
                <input
                  type="text"
                  value={productData.dose_per_serving}
                  onChange={(e) =>
                    setProductData({
                      ...productData,
                      dose_per_serving: e.target.value,
                    })
                  }
                  style={styles.formInput}
                />
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Perishable:</label>
                <input
                  type="checkbox"
                  checked={productData.perishable}
                  onChange={(e) =>
                    setProductData({
                      ...productData,
                      perishable: e.target.checked,
                    })
                  }
                  style={styles.formInput}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ ...styles.formElement, marginRight: 20 }}>
                  <label style={styles.formLabel}>Rating:</label>
                  <input
                    type="number"
                    value={productData?.rating_initial?.rating}
                    onChange={(e) =>
                      setProductData({
                        ...productData,
                        rating_initial: {
                          ...productData?.rating_initial,
                          rating: parseFloat(e.target.value) || 0,
                        },
                      })
                    }
                    style={{ ...styles.formInput, width: "100px" }}
                    min="0"
                    max="5"
                    step="0.1"
                  />
                </div>
                <div style={styles.formElement}>
                  <label style={styles.formLabel}>Voting:</label>
                  <input
                    type="number"
                    value={productData?.rating_initial?.voting}
                    onChange={(e) =>
                      setProductData({
                        ...productData,
                        rating_initial: {
                          ...productData?.rating_initial,
                          voting: parseInt(e.target.value) || 0,
                        },
                      })
                    }
                    style={{ ...styles.formInput, width: "100px" }}
                    min="0"
                  />
                </div>
              </div>

              <div style={styles.formElement}>
                <label style={styles.formLabel}>Inactive:</label>
                <input
                  type="checkbox"
                  checked={productData.inactive || false}
                  onChange={(e) =>
                    setProductData({
                      ...productData,
                      inactive: e.target.checked,
                    })
                  }
                  style={styles.formInput}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <div>
                  <button
                    type="submit"
                    style={{
                      ...styles.button,
                      backgroundColor: loading ? "silver" : "#478169",
                    }}
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </button>
                  <button
                    type="button"
                    onClick={handleDuplicateProduct}
                    style={{
                      ...styles.button,
                      backgroundColor: "transparent",
                      color: "black",
                      width: "150px",
                      marginLeft: "8px",
                      border: "1px solid gray",
                    }}
                    variant="outlined"
                  >
                    {loading ? "Duplicating..." : "Duplicate Product"}
                  </button>
                  <button
                    type="button"
                    onClick={() => window.location.reload()} // Reload the page
                    style={{
                      ...styles.button,
                      backgroundColor: "transparent",
                      color: "black",
                      width: "100px",
                      marginLeft: "8px",
                      border: "1px solid gray",
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <button
                  type="button"
                  onClick={() => setShowDeleteDialog(true)}
                  style={{
                    ...styles.button,
                    backgroundColor: "red",
                    color: "white",
                  }}
                >
                  Delete Product
                </button>
              </div>
            </div>
          </form>
        )}
        {currentTab === "compliance" && renderComplianceTab()}
        {currentTab === "images" && (
          <form onSubmit={handleImageUpload} style={styles.form}>
            <div style={{ padding: 20 }}>
              <div
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  display: "flex",
                  marginBottom: 50,
                }}
              >
                <SortableImageList images={productData.images} onSortEnd={onSortEnd} onDelete={deleteImage} axis="xy" />
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Upload Image:</label>
                <input ref={fileInputRef} type="file" onChange={handleFileChange} style={styles.formInput} accept="image/*" multiple />
              </div>
              <button
                type="submit"
                style={{
                  ...styles.button,
                  backgroundColor: loading ? "silver" : "#478169",
                }}
              >
                {loading ? "Uploading..." : "Upload Image"}
              </button>
            </div>
          </form>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h2>All Products</h2>
          <CSVLink
            {...generateCsvData()}
            filename="products_list.csv"
            className="btn btn-primary"
            target="_blank"
            style={{
              padding: "10px 20px",
              color: "black",
              textDecoration: "none",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          >
            Download Products as CSV
          </CSVLink>
        </div>
        <table style={styles.table}>
          <thead>
            <tr style={{ backgroundColor: "rgba(49,57,66,.1)" }}>
              <th style={{ ...styles.th, width: "120px", border: 0 }}>Product UID</th>
              <th style={{ ...styles.th, border: 0 }}>
                <TableSortLabel
                  active={sortConfig.key === "product_name"}
                  direction={sortConfig.key === "product_name" ? sortConfig.direction : "asc"}
                  onClick={() => handleSortRequest("product_name")}
                  style={{
                    ...styles.th,
                    border: 0,
                    fontWeight: "700",
                    color: "black",
                  }}
                >
                  Name
                </TableSortLabel>
              </th>
              <th style={{ ...styles.th, border: 0 }}>
                <TableSortLabel
                  active={sortConfig.key === "brand"}
                  direction={sortConfig.key === "brand" ? sortConfig.direction : "asc"}
                  onClick={() => handleSortRequest("brand")}
                  style={{ border: 0, fontWeight: "700", color: "black" }}
                >
                  Brand
                </TableSortLabel>
              </th>
              <th style={{ ...styles.th, border: 0 }}>
                <TableSortLabel
                  active={sortConfig.key === "brand"}
                  direction={sortConfig.key === "brand" ? sortConfig.direction : "asc"}
                  onClick={() => handleSortRequest("brand")}
                  style={{ border: 0, fontWeight: "700", color: "black" }}
                >
                  Sold
                </TableSortLabel>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedProducts.map((product) => (
              <tr key={product.id} style={styles.clickableRow} onClick={() => navigate(`/main/products/edit/${product.id}`)}>
                <td style={styles.td}>{`P${formatUID(product.product_uid)}`}</td>
                <td style={{ ...styles.tdName, fontWeight: "500" }}>{product.product_name}</td>
                <td style={styles.td}>
                  {product.brand} <span style={{ color: "silver" }}>{product.brand_name}</span>
                </td>
                <td style={styles.td}>{product.sold_count || 0}</td> {/* Display sold_count */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
};

const styles = {
  tabButton: {
    padding: "10px",
    backgroundColor: "white",
    cursor: "pointer",
    marginRight: 5,
    borderRadius: "5px 5px 0 0",
    fontSize: 12,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: 300,
    border: "1px solid #ccc",
    flex: 1,
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    width: "100%",
  },
  formElement: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    marginBottom: 10,
  },
  formLabel: {
    marginBottom: 5,
    fontSize: 12,
    textTransform: "uppercase",
    color: "#4a4141",
  },
  formInput: {
    padding: "10px 15px",
    marginBottom: 10,
    fontSize: 16,
    border: ".5px solid #ccc",
    borderRadius: 5,
  },
  formSelect: {
    fontSize: 16,
    border: "0.5px solid #ccc",
    borderRadius: 5,
    height: 38,
    backgroundColor: "#fff",
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "#478169",
    color: "white",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: 14,
    height: 50,
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  th: {
    border: "1px solid #d1d0d0",
    padding: "8px",
    textAlign: "left",
  },
  td: {
    border: "1px solid #d1d0d0",
    padding: "8px",
    color: "gray",
  },
  tdName: {
    border: "1px solid #d1d0d0",
    padding: "8px",
    cursor: "pointer",
  },
  clickableRow: {
    cursor: "pointer",
  },
};

export default EditProduct;
