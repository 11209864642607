import React, { useState, useEffect, useContext } from "react";
import { useFirestore } from "../../firebase/FirestoreContext";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, setDoc, getDoc, writeBatch } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../config";
import { Button } from "@mui/material";
import { EnvContext } from "../../../context/EnvContext";

const ShipmentCompliance = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore();

  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [currentTab, setCurrentTab] = useState("compliance_states");

  const [locations, setLocations] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const [newLocation, setNewLocation] = useState({
    name: "",
    shortName: "",
    shipmentPrice: "",
    description: "",
  });
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [complianceData, setComplianceData] = useState({});
  const [unsavedComplianceData, setUnsavedComplianceData] = useState({});
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  //
  const handleTabClick = (tab) => {
    setCurrentTab(tab);
  };

  //
  const handleCheckboxChange = (category, locationId, isChecked) => {
    setUnsavedComplianceData((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [locationId]: isChecked,
      },
    }));
    setHasUnsavedChanges(true);
  };

  const saveComplianceChanges = async () => {
    try {
      const batch = writeBatch(db);

      Object.entries(unsavedComplianceData).forEach(([category, locations]) => {
        const complianceRef = doc(db, `${collectionPath}SooperstockSettings/CategoriesCompliance/DataBase`, category);

        batch.set(complianceRef, locations, { merge: true });

        Object.entries(locations).forEach(([locationId, isCompliant]) => {
          products
            .filter((product) => product.product_category === category && product.compliance_type !== "custom")
            .forEach((product) => {
              const productRef = doc(db, `${collectionPath}Products/ProductsList/DataBase`, product.id);
              const updatedCompliance = [...(product.compliance || [])];

              if (isCompliant && !updatedCompliance.includes(locationId)) {
                updatedCompliance.push(locationId);
              } else if (!isCompliant && updatedCompliance.includes(locationId)) {
                updatedCompliance.splice(updatedCompliance.indexOf(locationId), 1);
              }

              batch.update(productRef, { compliance: updatedCompliance });
            });
        });
      });

      await batch.commit();

      // Merge unsaved changes into complianceData and reset unsavedComplianceData
      setComplianceData((prev) => {
        const mergedData = { ...prev };
        Object.entries(unsavedComplianceData).forEach(([category, locations]) => {
          mergedData[category] = {
            ...(mergedData[category] || {}),
            ...locations,
          };
        });
        return mergedData;
      });

      setUnsavedComplianceData({});
      setHasUnsavedChanges(false);

      toast.success("Compliance changes saved successfully.");
    } catch (error) {
      console.error("Error saving compliance changes:", error);
      toast.error("Failed to save compliance changes.");
    }
  };

  const cancelChanges = () => {
    setUnsavedComplianceData({});
    setHasUnsavedChanges(false);
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = ""; // This triggers the confirmation dialog.
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  //
  useEffect(() => {
    if (currentTab === "compliance_categories") {
      setCategories(config.productCategoryOptions); // Ensure categories are initialized
      const fetchComplianceData = async () => {
        try {
          const complianceCollectionRef = collection(db, `${collectionPath}SooperstockSettings/CategoriesCompliance/DataBase`);
          const snapshot = await getDocs(complianceCollectionRef);

          const compliance = {};
          snapshot.docs.forEach((doc) => {
            compliance[doc.id] = doc.data(); // Store compliance data with category name as key
          });

          setComplianceData(compliance);
        } catch (error) {
          console.error("Error fetching compliance data:", error);
          toast.error("Failed to fetch compliance data.");
        }
      };

      fetchComplianceData();
    }
  }, [currentTab, db, collectionPath]);

  //
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollectionRef = collection(db, `${collectionPath}Products/ProductsList/DataBase`);
        const snapshot = await getDocs(productsCollectionRef);
        const productsArray = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productsArray);
      } catch (error) {
        console.error("Error fetching products:", error);
        setProducts([]); // Ensure fallback to an empty array
      }
    };

    if (currentTab === "compliance_categories" || currentTab === "products") {
      fetchProducts();
    }
  }, [currentTab, db, collectionPath]);

  // Fetch proucts categories
  useEffect(() => {
    if (currentTab === "categories") {
      setCategories(config.productCategoryOptions); // Use categories from config
    }

    if (currentTab === "products") {
      const fetchProducts = async () => {
        const productsCollectionRef = collection(db, `${collectionPath}Products/ProductsList/DataBase`);
        try {
          const snapshot = await getDocs(productsCollectionRef);
          const productsArray = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          // Separate products based on compliance type
          setProducts(productsArray.filter((product) => product.compliance_type !== "custom"));
        } catch (error) {
          console.error("Error fetching products:", error);
          toast.error("Failed to fetch products.");
        }
      };

      fetchProducts();
    }
  }, [currentTab, db, collectionPath]);

  // Fetch all locations
  // Fetch all locations
  useEffect(() => {
    const fetchLocations = async () => {
      const locationsCollectionRef = collection(db, `${collectionPath}SooperstockSettings/ShipmentCompliance/DataBase`);
      try {
        const snapshot = await getDocs(locationsCollectionRef);
        const locationsArray = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .sort((a, b) => a.name.localeCompare(b.name)); // Sort locations alphabetically by name
        setLocations(locationsArray);
      } catch (error) {
        console.error("Error fetching locations:", error);
        toast.error("Failed to fetch locations.");
      }
    };

    fetchLocations();
  }, [db, collectionPath]);

  // Validate uniqueness of short name
  const isShortNameUnique = (shortName, excludeId = null) => {
    return !locations.some((location) => location.shortName === shortName && location.id !== excludeId);
  };

  //
  //
  //
  // update compliance for a specific category
  const updateCategoryCompliance = async (category, locationId, isCompliant) => {
    try {
      const batch = writeBatch(db); // Correctly initialize the batch
      const complianceRef = doc(db, `${collectionPath}SooperstockSettings/CategoriesCompliance/DataBase`, category);
      const productsCollectionRef = collection(db, `${collectionPath}Products/ProductsList/DataBase`);

      // Fetch all products for the category
      const productsSnapshot = await getDocs(productsCollectionRef);
      const productsArray = productsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Separate products into updated and not-updated lists
      const productsToUpdate = [];
      const productsNotUpdated = [];

      productsArray.forEach((product) => {
        if (product.product_category === category) {
          if (product.compliance_type !== "custom") {
            productsToUpdate.push(product);
          } else {
            productsNotUpdated.push(product);
          }
        }
      });

      // Update compliance data for eligible products
      productsToUpdate.forEach((product) => {
        const productRef = doc(db, `${collectionPath}Products/ProductsList/DataBase`, product.id);
        const updatedCompliance = product.compliance || [];
        if (isCompliant) {
          if (!updatedCompliance.includes(locationId)) {
            updatedCompliance.push(locationId); // Add location
          }
        } else {
          const index = updatedCompliance.indexOf(locationId);
          if (index > -1) updatedCompliance.splice(index, 1); // Remove location
        }
        batch.update(productRef, { compliance: updatedCompliance });
      });

      // Commit batch update
      await batch.commit();
      console.log("Batch update committed successfully.");

      // Update category compliance in Firestore
      await setDoc(complianceRef, { [locationId]: isCompliant }, { merge: true });

      // Update local state
      setComplianceData((prevData) => ({
        ...prevData,
        [category]: {
          ...(prevData[category] || {}),
          [locationId]: isCompliant,
        },
      }));

      // Show popup with results
      toast.success(
        `Category "${category}" updated successfully. Products updated: ${productsToUpdate.length}, Products not updated: ${productsNotUpdated.length}`
      );

      // Display detailed product lists in a modal or popup
      alert(
        `Products Updated:\n${productsToUpdate.map((p) => p.product_name).join(", ")}\n\nProducts Not Updated:\n${productsNotUpdated
          .map((p) => p.product_name)
          .join(", ")}`
      );
    } catch (error) {
      console.error("Error updating compliance:", error.message || error);
      toast.error("Failed to update category compliance.");
    }
  };

  // update products for a specific category
  const updateProductsForCategory = async (category) => {
    try {
      // Filter products for the category and compliance type
      const productsToUpdate = products.filter((product) => product.product_category === category && product.compliance_type === "category");

      if (productsToUpdate.length === 0) {
        toast.info(`No products to update for category "${category}".`);
        return;
      }

      console.log("Category:", category);
      console.log("Products to update:", productsToUpdate);

      // Update Firestore documents
      await Promise.all(
        productsToUpdate.map(async (product) => {
          const productRef = doc(db, `${collectionPath}Products/ProductsList/DataBase`, product.id);
          console.log("Updating product:", product.id);

          await updateDoc(productRef, {
            compliance: { ...(product.compliance || {}), updatedAt: new Date().toISOString() },
          });

          // Optionally update state immediately
          setProducts((prevProducts) =>
            prevProducts.map((p) => (p.id === product.id ? { ...p, compliance: { ...(p.compliance || {}), updatedAt: new Date().toISOString() } } : p))
          );
        })
      );

      toast.success(`Products for category "${category}" updated successfully.`);
    } catch (error) {
      console.error("Error updating products:", error);
      toast.error(`Failed to update products for category "${category}".`);
    }
  };

  //
  //
  //
  // Add new location
  const addLocation = async () => {
    if (!newLocation.name || !newLocation.shortName) {
      toast.error("State and Short Name are required.");
      return;
    }

    if (!isShortNameUnique(newLocation.shortName)) {
      toast.error("Short Name must be unique.");
      return;
    }

    const locationsCollectionRef = collection(db, `${collectionPath}SooperstockSettings/ShipmentCompliance/DataBase`);

    try {
      const docRef = await addDoc(locationsCollectionRef, newLocation);
      setLocations([...locations, { id: docRef.id, ...newLocation }]);
      toast.success("Location added successfully.");
      setNewLocation({ name: "", shortName: "", shipmentPrice: "", description: "" });
    } catch (error) {
      console.error("Error adding location:", error);
      toast.error("Failed to add location.");
    }
  };

  // Edit location
  const saveEdit = async (id) => {
    const locationIndex = locations.findIndex((loc) => loc.id === id);
    const updatedLocation = locations[locationIndex];

    if (!updatedLocation.name || !updatedLocation.shortName) {
      toast.error("State and Short Name are required.");
      return;
    }

    if (!isShortNameUnique(updatedLocation.shortName, id)) {
      toast.error("Short Name must be unique.");
      return;
    }

    const locationDocRef = doc(db, `${collectionPath}SooperstockSettings/ShipmentCompliance/DataBase`, id);

    try {
      await updateDoc(locationDocRef, updatedLocation);
      toast.success("Location updated successfully.");
      setEditingRow(null);
    } catch (error) {
      console.error("Error updating location:", error);
      toast.error("Failed to update location.");
    }
  };

  // Delete location
  const deleteLocation = async (id) => {
    const locationDocRef = doc(db, `${collectionPath}SooperstockSettings/ShipmentCompliance/DataBase`, id);

    try {
      await deleteDoc(locationDocRef);
      setLocations(locations.filter((loc) => loc.id !== id));
      toast.success("Location deleted successfully.");
    } catch (error) {
      console.error("Error deleting location:", error);
      toast.error("Failed to delete location.");
    }
  };

  // Render the table
  const renderLocationsTable = () => (
    <table style={styles.table}>
      <thead>
        <tr>
          <th style={styles.th}>State</th>
          <th style={styles.th}>Short Name</th>
          <th style={styles.th}>Shipment Price</th>
          <th style={styles.th}>Description</th>
          <th style={styles.th}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {locations.map((location) => (
          <tr key={location.id}>
            <td style={styles.td}>
              {editingRow === location.id ? (
                <input
                  value={location.name}
                  onChange={(e) => setLocations((prev) => prev.map((loc) => (loc.id === location.id ? { ...loc, name: e.target.value } : loc)))}
                  style={styles.input}
                />
              ) : (
                location.name
              )}
            </td>
            <td style={styles.td}>
              {editingRow === location.id ? (
                <input
                  value={location.shortName}
                  onChange={(e) => setLocations((prev) => prev.map((loc) => (loc.id === location.id ? { ...loc, shortName: e.target.value } : loc)))}
                  style={styles.input}
                />
              ) : (
                location.shortName
              )}
            </td>
            <td style={styles.td}>
              {editingRow === location.id ? (
                <input
                  value={location.shipmentPrice || ""}
                  onChange={(e) => setLocations((prev) => prev.map((loc) => (loc.id === location.id ? { ...loc, shipmentPrice: e.target.value } : loc)))}
                  style={styles.input}
                />
              ) : (
                location.shipmentPrice || "N/A"
              )}
            </td>
            <td style={styles.td}>
              {editingRow === location.id ? (
                <input
                  value={location.description || ""}
                  onChange={(e) => setLocations((prev) => prev.map((loc) => (loc.id === location.id ? { ...loc, description: e.target.value } : loc)))}
                  style={styles.input}
                />
              ) : (
                location.description || "N/A"
              )}
            </td>
            <td style={styles.td}>
              {editingRow === location.id ? (
                <Button onClick={() => saveEdit(location.id)} variant="contained" color="success" style={{ marginRight: "5px" }}>
                  Save
                </Button>
              ) : (
                <Button onClick={() => setEditingRow(location.id)} variant="contained" style={{ marginRight: "5px" }}>
                  Edit
                </Button>
              )}
            </td>
          </tr>
        ))}
        <tr>
          <td style={styles.td}>
            <input
              value={newLocation.name}
              onChange={(e) => setNewLocation({ ...newLocation, name: e.target.value })}
              placeholder="State"
              style={styles.input}
            />
          </td>
          <td style={styles.td}>
            <input
              value={newLocation.shortName}
              onChange={(e) => setNewLocation({ ...newLocation, shortName: e.target.value })}
              placeholder="Short Name"
              style={styles.input}
            />
          </td>
          <td style={styles.td}>
            <input
              value={newLocation.shipmentPrice}
              onChange={(e) => setNewLocation({ ...newLocation, shipmentPrice: e.target.value })}
              placeholder="Shipment Price"
              style={styles.input}
            />
          </td>
          <td style={styles.td}>
            <input
              value={newLocation.description}
              onChange={(e) => setNewLocation({ ...newLocation, description: e.target.value })}
              placeholder="Description"
              style={styles.input}
            />
          </td>
          <td style={styles.td}>
            <Button onClick={addLocation} variant="contained" color="primary">
              Add
            </Button>
          </td>
        </tr>
      </tbody>
    </table>
  );

  //

  const renderComplianceCategories = () => {
    return (
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Country</th>
            {categories.map((category) => (
              <th
                key={category}
                style={{
                  ...styles.th,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {category}
              </th>
            ))}
            <th style={styles.th}>All</th>
          </tr>
        </thead>
        <tbody>
          {locations.map((location) => (
            <tr key={location.shortName}>
              <td
                style={{ ...styles.td, width: "140px !important", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}
              >{`${location.name}`}</td>
              {categories.map((category) => (
                <td key={category} style={styles.td}>
                  <input
                    type="checkbox"
                    checked={unsavedComplianceData[category]?.[location.shortName] ?? complianceData[category]?.[location.shortName] ?? false}
                    onChange={(e) => handleCheckboxChange(category, location.shortName, e.target.checked)}
                  />
                </td>
              ))}
              <td style={styles.td}>
                <input
                  type="checkbox"
                  checked={categories.every(
                    (category) => unsavedComplianceData[category]?.[location.shortName] ?? complianceData[category]?.[location.shortName] ?? false
                  )}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    const updatedCompliance = categories.reduce((acc, category) => {
                      acc[category] = {
                        ...(unsavedComplianceData[category] || {}),
                        [location.shortName]: isChecked,
                      };
                      return acc;
                    }, {});

                    setUnsavedComplianceData((prev) => ({
                      ...prev,
                      ...updatedCompliance,
                    }));

                    setHasUnsavedChanges(true);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderComplianceProducts = () => (
    <table style={styles.table}>
      <thead>
        <tr>
          <th style={styles.th}>Product</th>
          <th style={styles.th}>Available Countries</th>
          <th style={styles.th}>Compliance Type</th>
        </tr>
      </thead>
      <tbody>
        {(products || []).map((product) => (
          <tr key={product.id}>
            {/* Product Name */}
            <td style={styles.td}>{product.product_name || "Unnamed Product"}</td>

            {/* Available Countries */}
            <td style={styles.td}>
              {(product.compliance || []).join(", ") || "-"} {/* Display all country codes */}
            </td>

            {/* Compliance Type */}
            <td style={styles.td}>{product.compliance_type || "-"}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div>
      <ToastContainer />
      <h2>Manage Locations</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginLeft: 20,
        }}
      >
        <div
          onClick={() => handleTabClick("compliance_states")}
          style={{
            ...styles.tabButton,
            backgroundColor: currentTab === "compliance_states" ? "#ff0000" : "#d5d4d4",
            color: currentTab === "compliance_states" ? "white" : "gray",
          }}
        >
          Compliance States
        </div>
        <div
          onClick={() => handleTabClick("compliance_categories")}
          style={{
            ...styles.tabButton,
            backgroundColor: currentTab === "compliance_categories" ? "#ff0000" : "#d5d4d4",
            color: currentTab === "compliance_categories" ? "white" : "gray",
          }}
        >
          Compliance Categories
        </div>

        <div
          onClick={() => handleTabClick("compliance_products")}
          style={{
            ...styles.tabButton,
            backgroundColor: currentTab === "compliance_products" ? "#ff0000" : "#d5d4d4",
            color: currentTab === "compliance_products" ? "white" : "gray",
          }}
        >
          Compliance Products
        </div>
      </div>

      {currentTab === "compliance_states" && renderLocationsTable()}
      {currentTab === "compliance_categories" && (
        <>
          {renderComplianceCategories()}
          <div style={{ marginTop: "20px" }}>
            <Button variant="contained" color="primary" onClick={saveComplianceChanges} disabled={!hasUnsavedChanges} style={{ marginRight: "10px" }}>
              Save
            </Button>
            <Button variant="outlined" color="secondary" onClick={cancelChanges} disabled={!hasUnsavedChanges}>
              Cancel
            </Button>
          </div>
        </>
      )}
      {currentTab === "compliance_products" && renderComplianceProducts()}
    </div>
  );
};

const styles = {
  tabButton: {
    padding: "10px",
    backgroundColor: "white",
    cursor: "pointer",
    marginRight: 5,
    borderRadius: "5px 5px 0 0",
    fontSize: 12,
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    tableLayout: "fixed", // Ensures columns have consistent widths
  },
  th: {
    border: "1px solid #d1d0d0",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f4f4f4",
    width: "20%", // Set widths for each column as needed
  },
  td: {
    border: "1px solid #d1d0d0",
    padding: "8px",
  },
  input: {
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    width: "95%", // Adjust input width to avoid overflow
    boxSizing: "border-box", // Prevent padding from increasing width
  },
  button: {
    padding: "5px 10px",
    borderRadius: "5px",
    fontSize: "14px",
    color: "white",
    cursor: "pointer",
  },
  saveButton: {
    backgroundColor: "green",
    marginRight: "5px",
  },
  deleteButton: {
    backgroundColor: "red",
  },
  addButton: {
    backgroundColor: "blue",
  },
};

export default ShipmentCompliance;
